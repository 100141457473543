<template>
  <div class="addy-plus-account-list">
    <Permission :overwrite="['read']"/>
    <div class="title">Accounts</div>
    <b-table :data="accounts" hoverable>
      <b-table-column field="name" label="Account Name" v-slot="props">
        <div class="is-flex is-align-items-center">
          <span class="has-text-primary is-clickable is-flex-grow-1" @click="toCorporationsPage(props.row.id)">{{ props.row.name }}</span>
          <a v-if="props.row.isOwner" @click="toAccountSettings(props.row.id)">
            <b-icon pack="fas" icon="cog" size="is-medium"></b-icon>
          </a>
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import Permission from '@views/addy_plus/base/Permission.vue'

export default {
  title: 'Dashboard',
  mixins: [pageTitleMixin],
  components: {
    Permission,
  },
  computed: {
    ...mapState('addyPlusBase', ['accounts']),
  },
  methods: {
    ...mapMutations('addyPlusBase', ['setActiveAccount']),
    toCorporationsPage(accountId) {
      this.setActiveAccount(accountId)
      this.$router.push(`/addyPlus/${accountId}/corporations`)
    },
    toAccountSettings(accountId) {
      this.setActiveAccount(accountId)
      this.$router.push(`/addyPlus/${accountId}/settings`)
    }
  },
}
</script>
<style lang="scss" scoped>
.addy-plus-account-list {
  font-size: 16px;
}
</style>
